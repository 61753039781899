.i{
    display: flex;
    flex-direction: row;
    height: 80vh;
    font-family: 'Kanit', sans-serif;
    width: 90vw;
    border-radius: 25px;
    border-style: solid;
    border-color: #e0e0e0;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2vh;
}

.i-left{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-left-wrapper{
    padding: 50px;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.i-intro{
    font-size: 30px;
    font-weight: 300;
}

.i-name{
    font-size: 60px;
}

.i-title{
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper{
    height: 100%;
    animation: move 15s ease-in-out infinite alternate;
}

@keyframes move {
    20% {
        transform: translateY(-50px);
    }
    40% {
        transform: translateY(-100px);
    }
    60% {
        transform: translateY(-150px);
    }
    80% {
        transform: translateY(-200px);
    }
    100% {
        transform: translateY(-250px);
    }
    
}

.i-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: green;
    background-color: white;
    display: flex;
    align-items: center;
}

.i-img{
    width: 90%;
    height: 90%;
    object-fit: contain;
    padding-top: 2%;
}