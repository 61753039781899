.menu-items {
    text-decoration: none;
    color: black;
    list-style-type: none;
    font-size: 0.1vw;
    padding: 0.7rem 1rem;
}

.menu-items a {
    display: block;
    color: inherit;
    text-decoration: none;
    align-items: center;
    font-size: 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    padding: 0;
}

.menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: 1vw;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
}

/* button span {
    margin-left: 3px;
} */

.menu-items > a, .menu-items button {
    text-align: left;
}

.menu-items a:hover,
.menu-items button:hover {
    background-color: #f2f2f2;
}