.snake-container {
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 82vh;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
}

.snake-left-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.snake-info-wrapper {
    align-self: flex-end;
    margin-right: 3vw;
}

.snake-name {
    font-weight: bold;
    font-size: 3.7vw;
    margin-bottom: 2vh;
}

.snake-keys {
    font-size: 0.8vw;
    font-style: italic;
}

.grid-container {
}

.grid-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.snake-button {
    cursor: pointer; 
    height: 10vh;
    width: 13vw;
    font-size: 1.5vw;
    border-radius: 15px;
    color: black;
    position: absolute;
    align-self: center;
}

.snake-button:hover {
    opacity: 60%;
}

.grid {

}

.snake-right-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.snake-right-wrapper {
    align-self: flex-start;
    margin-left: 3vw;
}


.snake-score-wrapper {
    display: flex;
    flex-direction: column;
}

.snake-score-text {
    font-size: 3vw;
}

.snake-bord {
    display: flex;
    width: 15vw;
    height: 10vh;
    border-radius: 20px;
    border-style: solid;
    border-color: black;
    border-width: 4px;
    background-color: black;
    color: white;
    align-items: center;
    justify-content: center;
}

.snake-score {
    font-size: 8vh;
    font-weight: 600;
}

.snake-start {
    height: 10vh;
    width: 13vw;
    font-size: 1.5vw;
    color: black;
}