.chat-container{
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    font-family: 'Kanit', sans-serif;
}

.chat-header{
    color: black;
    font-weight: 600;
    font-style: italic;
    padding-top: 1vh;
}