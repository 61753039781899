html{
    margin-left: calc(100vw - 100%);
    margin-right: 0;
}

.proj-container{
    display: flex;
    margin: 0;
    flex-direction: column;
    align-items: center;
    font-family: 'Kanit', sans-serif;
}

.proj-header{
    color: black;
    font-weight: 600;
    font-style: italic;
    padding-top: 1vh;
}

.p1{
    display: flex;
    flex-direction: row;
    height: 80vh;
    font-family: 'Kanit', sans-serif;
    width: 90vw;
    border-radius: 25px;
    border-style: solid;
    border-color: #e0e0e0;
    justify-content: center;
    margin: auto 0;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.p1-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.p1-left-wrapper{
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vh;
}

.p1-fan-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.p1-fanIcon{
    width: 5vh;
    height: 5vh;
    object-fit: contain;
    padding: 2px;
    border: 1px solid black;
    border-radius: 10px;
}

.p1-fanalyze{
    font-size: 2.1vw;
    font-weight: 600;
    color: green;
    padding-left: 1vw;
}

.p1-date{
    font-size: 1.05vw;
    padding-top: 1vh;
}

.p1-stack{
   font-size: 0.782vw;
   padding-top: 1vh;
}

.p1-desc{
    font-size: 0.782vw;
    padding-top: 2vh;
}

.p1-ios-link{
    margin-top: auto;
}

.p1-android-link{
    padding-top: 1vh;
}

.p1-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-image {
    width: 100%;
    height: 60vh;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    height: 100vh;
    justify-content: center;
}

.p2{
    display: flex;
    flex-direction: row;
    height: 80vh;
    font-family: 'Kanit', sans-serif;
    width: 90vw;
    border-radius: 25px;
    border-style: solid;
    border-color: #e0e0e0;
    justify-content: center;
    margin: auto 0;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.p2-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.p2-left-wrapper{
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vh;
}

.p2-school-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.p2-davisIcon{
    width: 5vh;
    height: 5vh;
    object-fit: contain;
    padding: 2px;
    border: 1px solid black;
    border-radius: 10px;
}

.p2-school{
    font-size: 2.1vw;
    font-weight: 600;
    color: #002855;
    padding-left: 1vw;
}

.p2-date{
    font-size: 1.05vw;
    padding-top: 1vh;
}

.p2-classes{
   font-size: 0.782vw;
   padding-top: 1vh;
}

.p2-desc{
    font-size: 0.782vw;
    padding-top: 2vh;
}

.p2-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.snake{
    display: flex;
    flex-direction: row;
    height: 80vh;
    font-family: 'Kanit', sans-serif;
    width: 90vw;
    border-radius: 25px;
    border-style: solid;
    border-color: #e0e0e0;
    justify-content: center;
    margin: auto 0;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.snake-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.snake-left-wrapper{
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3vh;
}

.snake-title{
    font-size: 4.2vh;
    font-weight: 600;
    color: black;
}

.snake-desc{
    font-size: 15px;
    padding-top: 2vh;
}

.source-git-link {
    margin-top: 2vh;
}

.snake-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.snake-link{
    padding-top: 2vh;
}

.snake-img{
    width: 90%;
    height: 90%;
    object-fit: contain;
    padding-top: 2%;
}