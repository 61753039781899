.foot{
    display: flex;
    flex-direction: column;
    height: 10vh;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.foot-wrapper{
    display: flex;
    flex-direction: row;
}

.signature{
    padding-top: 1vh;
    text-align: center;
    font-family: 'Kanit', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: black;
}