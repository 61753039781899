.res-page{
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    font-family: 'Kanit', sans-serif;
}
.res-header{
    color: black;
    font-weight: 600;
    font-style: italic;
    padding-top: 1vh;
}

.pdf-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70vh;
    width: 80vw;
}