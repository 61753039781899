.apps-container{
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    font-family: 'Kanit', sans-serif;
}

.apps-icons-container{
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.apps-header{
    color: black;
    font-weight: 600;
    font-style: italic;
    padding-top: 1vh;
}

.snake-app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 3vw;
}
.snake-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    height: 150px;
    width: 150px;
    border: 2px solid black;
    border-radius: 15px;
    background-color: rgb(202, 176, 127);
}

.snake-app-name {
    font-family: 'Kanit', sans-serif;
    font-weight: 800;
    font-style: italic;
    font-size: 20px;
}

.snake-icon:hover {
    opacity: 60%;
}

.snake-img{
    width: 100%;
    height: 100%;
}

.chat-app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.chat-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    height: 150px;
    width: 150px;
    border: 2px solid black;
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
}

.chat-icon:hover {
    opacity: 60%;
}

.chat-app-name {
    font-family: 'Kanit', sans-serif;
    font-weight: 800;
    font-style: italic;
    font-size: 20px;
}

.chat-app:hover {
    opacity: 60%;
}

.chat-img{
    width: 100%;
    height: 100%;
}